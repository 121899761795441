<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedConstantes"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'constant-graph'}"
          class="btn btn-outline-third"
        >
          <i class="icofont icofont-chart-histogram" />
        </router-link>
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-constante"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      id="add-constante"
      title="Enregistrer les constantes de l'animal"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <animal-detail :show-detail="true" />
        </div>
        <div class="form-group">
          <label for="dateOperation">Date de l'opération</label>
          <datepicker
            id="dateOperation"
            name="dateOperation"
            input-class="form-control required"
            :disabled-dates="disabledOperationDate"
            format="dd/MM/yyyy"
            placeholder="dd/mm/yyyy"
            v-model="operationDate"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="poid">Poids (Kg)</label>
              <input
                type="text"
                class="form-control"
                id="poid"
                name="poid"
                v-model="poid"
                placeholder="Donner le poids de l'animal"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="temperature">Temperature (°C)</label>
              <input
                type="text"
                class="form-control"
                id="temperature"
                name="temperature"
                v-model="temperature"
                placeholder="Donner la temperature de l'animal"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="fc">Fréquence cardiaque</label>
              <input
                type="number"
                class="form-control"
                id="fc"
                name="fc"
                v-model="fc"
                placeholder="Donnez la fréquence cardiaque de l'animal"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="fr">Fréquence respiratoire</label>
              <input
                type="number"
                class="form-control"
                id="fr"
                name="fr"
                v-model="fr"
                placeholder="Donnez la fréquence respiratoire de l'animal"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="trc">Temps de Recolorisation Capilaire</label>
              <select
                v-select="{placeholder: 'Selectionnez le TRC'}"
                class="form-control"
                v-model="trc"
                name="trc"
                id="trc"
              >
                <option />
                <option
                  v-for="t in trcs"
                  :value="t.value"
                  :key="t.value"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disable': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else>Ajouter</span>
          </button>
        </div>
      </form>
    </modal>


    <modal
      id="update-constante"
      title="Modifier une constante d'un l'animal"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="updating"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <animal-detail
            :animal-using="animalUsing"
            :show-detail="true"
          />
        </div>
        <div class="form-group">
          <label for="update-dateOperation">Date de l'opération</label>
          <datepicker
            id="update-dateOperation"
            name="update-dateOperation"
            input-class="form-control required"
            :disabled-dates="disabledOperationDate"
            format="dd/MM/yyyy"
            placeholder="dd/mm/yyyy"
            v-model="operationDate"
          />
        </div>  
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="update-poid">Poids (Kg)</label>
              <input
                type="text"
                class="form-control"
                id="update-poid"
                name="update-poid"
                v-model="poid"
                placeholder="Donner le poids de l'animal"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="update-temperature">Temperature (°C)</label>
              <input
                type="text"
                class="form-control"
                id="update-temperature"
                name="update-temperature"
                v-model="temperature"
                placeholder="Donner la temperature de l'animal"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="update-fc">Fréquence cardiaque</label>
              <input
                type="number"
                class="form-control"
                id="update-fc"
                name="update-fc"
                v-model="fc"
                placeholder="Donnez la fréquence cardiaque de l'animal"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="update-fr">Fréquence respiratoire</label>
              <input
                type="number"
                class="form-control"
                id="update-fr"
                name="update-fr"
                v-model="fr"
                placeholder="Donnez la fréquence respiratoire de l'animal"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="update-trc">Temps de Recolorisation Capilaire</label>
              <select
                v-select="{placeholder: 'Selectionnez le TRC'}"
                class="form-control"
                v-model="trc"
                name="update-trc"
                id="update-trc"
              >
                <option />
                <option
                  v-for="t in trcs"
                  :value="t.value"
                  :key="t.value"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else>Enregistrer</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import moment from 'moment'
    import Datepicker from 'vuejs-datepicker'
    import navbar from '../../../components/navbar.vue'
    import DataTable from '../../../components/dataTable/local.vue'
    import {  DATE_TYPE, COMPONENT_TYPE, NUMERIC_TYPE, TEXT_TYPE} from '../../../components/dataTable/dataType'
    import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
    import Modal from '../../../components/modal.vue'
    import { ADD_CONSTANTE, UPDATE_CONSTANTE  } from '../../../graphql/identification'
    import { TRCS } from '../../../constants/app'
    const Animal = () => import('../../../components/sanitaire/animal.vue')
    const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
    const Trc = () => import('../../../components/identification/constante/trc.vue')
    const Actions = () => import('../../../components/identification/constante/actions.vue')
    export default {
        components: {navbar, DataTable, Modal, AnimalDetail, Datepicker },
        data(){
            return {
              navbarItems: [{
                libelle: 'Identification'
              },
              {
                libelle: 'Constantes'
              }],
              pageIcon: 'la-stethoscope',
              pageTitle: 'Constantes',
              pageDescription: 'Enregistrer les constantes des animaux pour mieux suivre leur évolution',
              has_error: false,
              error_msg: null,
              temperature: null,
              animalUsing: null,
              poid: null,
              fr: null,
              fc: null,
              trc: null,
              operationDate: new Date()
            }
        },
        watch: {
          selectedObject: {
            handler(){
              if(this.selectedObject !== null){
                this.setSelectedAnimal(this.selectedObject.animal)
                this.animalUsing = this.selectedObject.animal
                this.temperature = this.selectedObject.temperature
                this.fc = this.selectedObject.fc
                this.fr = this.selectedObject.fr
                this.poid = this.selectedObject.poid
                this.trc = this.selectedObject.trc
                this.operationDate = this.selectedObject.operationDate
              }
            },
            deep: true
          }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setLoading: 'SET_LOADING',
                setSelectedAnimal: 'identification/SET_SELECTED_ANIMAL'
            }),
            initForm(){
              this.temperature = null
              this.poid = null
              this.fr = null,
              this.fc = null
              this.trc = null
              this.setLoading(false)
              this.operationDate =  new Date()
            },
            adding() {
              this.setLoading(true)
              let data = {
                  animal: this.selectedAnimal,
                  temperature: parseFloat(this.temperature),
                  poid: parseFloat(this.poid),
                  fr: parseInt(this.fr),
                  fc: parseInt(this.fc),
                  trc: parseFloat(this.trc),
                  operationDate: this.operationDate
              }
              this.$apollo.mutate({
                  mutation: ADD_CONSTANTE,
                  variables: {
                      "constante": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Constante ajouter sur l'animal ${this.selectedAnimal} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  this.setLoading(false)
                  this.has_error = true
                  this.error_msg = error
              })
            },
            updating(){
              this.setLoading(true)
              let data = {
                  animal: this.selectedAnimal,
                  temperature: parseFloat(this.temperature),
                  poid: parseFloat(this.poid),
                  fr: parseInt(this.fr),
                  fc: parseInt(this.fc),
                  trc: parseFloat(this.trc),
                  operationDate: this.operationDate
              }
              this.$apollo.mutate({
                  mutation: UPDATE_CONSTANTE,
                  variables: {
                      "constante": {
                          ...data
                      },
                      "uid": this.selectedObject.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Mise a jour de constante sur l'animal ${this.selectedAnimal} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  this.setLoading(false)
                  this.has_error = true
                  this.error_msg = error
              })
            }
        },
        computed: {
            ...mapGetters({
                selectedAnimal: 'identification/selectedAnimal',
                selectedObject: 'selectedObject',
                constantes: 'identification/constantes',
                animaux: 'identification/animaux',
                is_veterinaire: 'auth/is_veterinaire',
                is_super_admin: 'auth/is_super_admin',
                is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
                is_assistant_veto_major: 'auth/is_assistant_veto_major',
                loading: 'loading'
            }),
            trcs(){
              return TRCS
            },
            sortedConstantes(){
                return [...this.constantes].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
            },
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                    {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                    {label: 'Poids (Kg)', name: 'poid', type: NUMERIC_TYPE, sortable:true},
                    {label: 'Temperature (°C)', name: 'temperature', type: NUMERIC_TYPE, sortable: true},
                    {label: 'Frequence cardiaque', name: 'fc', type: NUMERIC_TYPE, sortable: true},
                    {label: 'Frequence respiratoire', name: 'fr', type: NUMERIC_TYPE, sortable: true},
                    {label: 'Temp de Recolorisation Capilaire', name: 'trc', type: COMPONENT_TYPE, component: Trc},
                    {label: 'Date prise', name: 'operationDate', type: DATE_TYPE, sortable: true},
                    {label: 'Createur', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                    {label: 'Date création', name: 'createdAt', type: DATE_TYPE, sortable: true},
                    {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                ]
            },
            disabledOperationDate(){
              let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
                if(animal !== null && animal !== undefined){
                    return {
                        to: new Date(animal.dateNaissance),
                        from: new Date()
                    }
                }
                return {
                    to: new Date(),
                    from: new Date()
                }
            },
            canAdd(){
              return this.is_super_admin || this.is_veterinaire || this.is_assistant_veterinaire || this.is_assistant_veto_major
            }
        }
    }
    </script>
  
    <style lang="scss" scoped>
    .medicaments{
          legend{
              position: relative;
              div{
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  a{
                      margin-left: 5px;
                  }
              }
          }
    }
    
    </style>